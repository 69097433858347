import { Component } from "vue-property-decorator"
import { namespace } from "vuex-class"
import { mixins } from "vue-class-component"
import productsData from "@/data/planos/ControleExpress.json"
import regionProducstData from "@/data/planos/ControleExpressRegionAtackDefense.json"
import { MixinRouteNavigation } from "@/mixins/navigator/MixinRouteNavigation"
const cacheStore = namespace("cacheStore")
const payloadStore = namespace("payloadStore")
Component.registerHooks([
  "beforeRouteEnter",
])
@Component
export class ServicesProductAPI extends mixins(
  MixinRouteNavigation,
) {
  beforeRouteEnter (
    to: {
      name: string;
      params: {
        sku: string | RegExp;
      }
    },
    _from: never,
    next: (arg0: (vm) => void) => void,
  ): void {
    next(vm => {
      if (to.name && /formulario/.test(to.name)) {
        if (to.params && to.params.sku) {
          const FILTERED_PRODUCTS_DATABASE: typeof vm.allProductDataBase = {}
          Object.keys(vm.allProductDataBase).forEach(product => {
            if (new RegExp(to.params.sku).test(product)) {
              FILTERED_PRODUCTS_DATABASE[product] = vm.allProductDataBase[product]
            }
          })
          vm.allProductDataBase = FILTERED_PRODUCTS_DATABASE
        }
      }
    })
  }

  @cacheStore.Getter("CacheRastreamentoUsuario") cacheRastreamentoUsuario
  @payloadStore.Getter("PayloadFidelityOrder") payloadFidelityOrder
  @payloadStore.Getter("PayloadServiceOrder") payloadServiceOrder
  @payloadStore.Action("ActionPayloadFidelityOrder") actionPayloadFidelityOrder
  @payloadStore.Action("ActionPayloadServiceOrder") actionPayloadServiceOrder
  @payloadStore.Getter("PayloadProductSKUOrder") payloadProductSKUOrder
  @payloadStore.Action("ActionPayloadProductSKUOrder") actionPayloadProductSKUOrder
  @payloadStore.Action("ActionPayloadProductCreateOrder") actionPayloadProductCreateOrder
  allProductDataBase = productsData
  temporaryDialog = ""
  viewOrderProductId = ""

  get orderProductId (): string {
    const ORDER_PRODUCT_ID = this.payloadProductSKUOrder()
    if (!ORDER_PRODUCT_ID) {
      this.createSpotlightItem().then(resolveSpotlightItem => {
        this.viewOrderProductId = resolveSpotlightItem
      })
      return this.viewOrderProductId
    } else {
      return ORDER_PRODUCT_ID
    }
  }

  set orderProductId (value: string) {
    this.actionPayloadProductSKUOrder(value)
  }

  get orderAcceptLoyalty (): boolean {
    return this.payloadFidelityOrder()
  }

  set orderAcceptLoyalty (value: boolean) {
    this.actionPayloadFidelityOrder(value)
  }

  get orderServiceChose (): boolean {
    return this.payloadServiceOrder()
  }

  set orderServiceChose (value: boolean) {
    this.actionPayloadServiceOrder(value)
  }

  async createSpotlightItem (): Promise<string> {
    return new Promise(resolve => {
      const to = this.$route
      Object.keys(this.allProductDataBase).map(product => {
        if ((to.params && to.params.sku) && this.allProductDataBase[to.params.sku]) {
          if (this.allProductDataBase[product].sku === to.params.sku) {
            this.actionPayloadProductSKUOrder(product)
            resolve(product)
          }
        } else
        if (this.allProductDataBase[product].spotlight) {
          this.actionPayloadProductSKUOrder(product)
          resolve(product)
        }
      })
    })
  }

  async allProductsSeguimentData (seguiment: string): Promise<void> {
    const USER_DATA_REGION = this.cacheRastreamentoUsuario()
    Object.keys(productsData).filter(productItem => {
      if ((new RegExp(seguiment)).test(String(productsData[productItem].seguiment || ""))) {
        if (productsData[productItem].timId["// timIdFidelityAttackDefence"]) {
          if (!USER_DATA_REGION) return
          if (productsData[productItem].timId["// timIdFidelityAttackDefence"]) {
            Object.keys(productsData[productItem].timId["// timIdFidelityAttackDefence"]).forEach(regionItem => {
              regionProducstData[regionItem].forEach((regionProductDDD: {
                ddd: string[];
              }) => {
                if (regionProductDDD.ddd) {
                  if (new RegExp(`^(${regionProductDDD.ddd.join("|")})$`).test(String(USER_DATA_REGION.ddd) || "")) {
                    productsData[productItem].timId.fidelity = productsData[productItem].timId["// timIdFidelityAttackDefence"][regionItem]
                  }
                }
              })
            })
          }
        }
        if (productsData[productItem].price["// priceFidelityAttackDefence"]) {
          if (!USER_DATA_REGION) return
          if (productsData[productItem].price["// priceFidelityAttackDefence"]) {
            Object.keys(productsData[productItem].price["// priceFidelityAttackDefence"]).forEach(regionItem => {
              regionProducstData[regionItem].forEach((regionProductDDD: {
                ddd: string[];
              }) => {
                if (regionProductDDD.ddd) {
                  if (new RegExp(`^(${regionProductDDD.ddd.join("|")})$`).test(String(USER_DATA_REGION.ddd) || "")) {
                    productsData[productItem].price.fidelity = productsData[productItem].price["// priceFidelityAttackDefence"][regionItem]
                  }
                }
              })
            })
          }
        }
      }
    })
  }

  toggleActivePlan (id: string): void {
    this.orderProductId = id
  }

  addTocartActivePlan (): void {
    this.actionPayloadProductCreateOrder(productsData[this.payloadProductSKUOrder()]).then(payloadProductCreateOrderResponse => {
      this.gotoFormCadastro(payloadProductCreateOrderResponse)
    })
  }

  get viewCartProductData (): Record<string, string> {
    if (!this.orderProductId) return {}
    return this.allProductDataBase[this.orderProductId]
  }
}
