import { Component, Vue } from "vue-property-decorator"
import productsData from "@/data/planos/ControleExpress.json"
@Component
export class MixinRouteNavigation extends Vue {
  gotoFormCadastro (data?: Record<string, string|number>): void {
    if ((data && data.sku) ? productsData[data.sku] : productsData[this.$route.params.sku]) {
      this.$router.push(`/pedido/${(data && data.sku) ? data.sku : this.$route.params.sku}/cadastro`)
    }
  }

  gotoFormDadosPessoais (): void {
    if (productsData[this.$route.params.sku]) {
      this.$router.push(`/pedido/${this.$route.params.sku}/dados-pessoais`)
    }
  }

  gotoFormDadosDePagamento (): void {
    if (productsData[this.$route.params.sku]) {
      this.$router.push(`/pedido/${this.$route.params.sku}/dados-de-pagamento`)
    }
  }

  gotoFormResumoDoPedidoSucesso (): void {
    if (productsData[this.$route.params.sku]) {
      this.$router.push(`/pedido/${this.$route.params.sku}/resumo-do-pedido`)
    }
  }

  gotoFormRestartApplication (): void {
    location.assign(location.origin)
  }
}
