import { Component, Vue } from "vue-property-decorator"
@Component
export class MixinFormat extends Vue {
  formatedPrice (price: number, type?: string): string|string[] {
    if (price === undefined) return ""
    let valorFinal = ""
    if (price === 0) {
      valorFinal = "0,00"
    } else {
      const p = price.toString()
      if (/\.|,/.test(p)) {
        valorFinal = `${p.substring(0, p.length - 2).replace(/\W/g, "")},${p.substring(p.length - 3).replace(/\W/g, "")}`
      } else {
        valorFinal = `${p.substring(0, p.length - 2).replace(/\W/g, "")},${p.substring(p.length - 2).replace(/\W/g, "")}`
      }
    }
    if (type) {
      if (/float/.test(type)) {
        return valorFinal.replace(/\.|,/, ".")
      } else if (/split/.test(type)) {
        return String(valorFinal || "").split(/\.|,/)
      } else {
        return valorFinal
      }
    } else {
      return `R$ ${valorFinal}`
    }
  }

  formatedFranchise (franchise: number, type?: string): string|string[] {
    if (franchise === undefined) return ""
    let valorFinal = ""
    if (type) {
      if (/number/.test(type)) {
        valorFinal = String((franchise / 1000) || "")
      } else if (/type/.test(type)) {
        valorFinal = String(franchise || "").length >= 4 ? "GB" : "MB"
      }
    } else {
      valorFinal = `${(franchise / 1000)}${String(franchise || "").length >= 4 ? "GB" : "MB"}`
    }
    return String(valorFinal || "")
  }
}
